<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item">
          <a-select :options="cateList" v-model="queryParams.cateId" style="width: 200px" allowClear placeholder="商品分类"
                    @change="search(1)"></a-select>
        </div>
        <div class="one-item">
          <a-select
              class="input"
              v-model="queryParams.platform"
              placeholder="来源"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict.goodsPlatform" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="items" style="flex: none">
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.goodsTitle"
              placeholder="商品名称"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        <a-button v-hasPermission="'goods:add'" type="primary" @click="add" style="margin-left:20px;" icon="plus">
          新增
        </a-button>

        <a-button v-hasPermission="'goods:import'" type="success" @click="importGoodsData" style="margin-left:20px;"
                  icon="import">
          导入
        </a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="masterImage" slot-scope="text, record">
        <div v-if="record.masterImage" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(record.masterImage || '') "
              fit="contain"
              :preview-src-list="[record.masterImage]"
          />
        </div>
      </template>
      <template slot="status" slot-scope="text, record">
        <a-switch :checked="record.status==1" @change="changeTopState(record,'status')" :loading1="showLoading"/>
        <!--        <a-tag :color="record.status | dictName('validStatus', 'color')">{{-->
        <!--            record.status | dictName("validStatus")-->
        <!--          }}-->
        <!--        </a-tag>-->
      </template>
      <template slot="platform" slot-scope="text, record">
        <a-tag :color="record.platform | dictName('goodsPlatform', 'color')">{{
            record.platform | dictName("goodsPlatform")
          }}
        </a-tag>
      </template>

      <template slot="hotTop" slot-scope="text, record">
        <a-switch :checked="text==1" @change="changeTopState(record,'hotTop')" :loading1="showLoading"/>
      </template>
      <template slot="isTop" slot-scope="text, record">
        <a-switch :checked="text==1" @change="changeTopState(record,'isTop')" :loading1="showLoading"/>
      </template>
      <template slot="goodsTitle" slot-scope="text, record">
        <div>
          <h3>{{ record.goodsTitle }}</h3>
          <div style="color:#999;zoom:0.9" v-if="record.subTitle">{{ record.subTitle }}</div>
        </div>
      </template>

      <template slot="price" slot-scope="text, record">
        <div class="red">￥{{ record.price }}</div>
        <div style="text-decoration: line-through;margin-left:2px" v-if="record.price!=record.orgPrice">
          ￥{{ record.orgPrice }}
        </div>
      </template>

      <template slot="operation" slot-scope="text, record">
        <a-dropdown
            v-hasAnyPermission="['goods:edit', 'goods:delete','goods:view' ]"
        >
          <a-menu slot="overlay">
            <a-menu-item key="modify" @click="modify($event, record)"
                         v-hasPermission="'goods:edit'">
              <a-icon type="setting"/>
              修改
            </a-menu-item>
            <a-menu-divider/>
            <a-menu-item
                key="delete"
                v-hasPermission="'goods:delete'"
                style="color: red"
                @click="getDelete($event, record)"
            >
              <a-icon type="delete"/>
              删除
            </a-menu-item>
          </a-menu>
          <a-button>
            操作
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
      </template>
    </a-table>

    <!-- 导入中提示页面-->
    <a-modal
        title=""
        :visible="showImporting"
        :maskClosable="false"
        :closable="false"
        centered
        :footer="null"
    >
      <div style="font-size: 1.2em;color:red;font-weight: 700;text-align: center">
        <a-icon type="loading"/>
        正在导入，请稍候...（请勿关闭当前窗口，请勿刷新页面）
      </div>
    </a-modal>
    <modify-modal ref="modifyModal" @success="editSuccess"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import modifyModal from "./modifyGoodsModal";

export default {
  name: "GoodsList",
  mixins: [ListMixin],
  components: {modifyModal},
  data() {
    return {
      listGetUrl: "goods/goodsList",
      listMethod: "get",
      goodsCateList: [],
      showLoading: false,
      showImporting: false
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "Logo",
          width: 80,
          align: "center",
          dataIndex: "masterImage",
          scopedSlots: {customRender: "masterImage"},
        },
        {
          title: "分类",
          align: "center",
          width: 100,
          dataIndex: "cateName",
        },
        {
          title: "商品名称",
          align: "center",
          width: 300,
          dataIndex: "goodsTitle",
          scopedSlots: {customRender: "goodsTitle"},
        },

        {
          title: "状态",
          align: "center",
          width: 80,
          dataIndex: "status",
          scopedSlots: {customRender: "status"},
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order
        },
        {
          title: "是否推荐",
          align: "center",
          width: 100,
          dataIndex: "isTop",
          scopedSlots: {customRender: "isTop"},
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'isTop' && sortedInfo.order
        },
        {
          title: "是否热门",
          align: "center",
          width: 100,
          dataIndex: "hotTop",
          scopedSlots: {customRender: "hotTop"},
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'hotTop' && sortedInfo.order
        },
        {
          title: "商品来源",
          align: "center",
          width: 80,
          dataIndex: "platform",
          scopedSlots: {customRender: "platform"},
        },
        {
          title: "商品价格",
          align: "center",
          width: 100,
          dataIndex: "price",
          scopedSlots: {customRender: "price"},
        },
        {
          title: "销量",
          align: "center",
          width: 80,
          dataIndex: "salesTip",
        },
        {
          title: "赞数量",
          align: "center",
          width: 60,
          dataIndex: "likeCount",
        },
        {
          title: "踩数量",
          align: "center",
          width: 60,
          dataIndex: "dislikeCount",
        },
        {
          title: "评论数量",
          align: "center",
          width: 80,
          dataIndex: "commentCount",
        },
        {
          title: "打开数量",
          align: "center",
          width: 80,
          dataIndex: "openCount",
        },
        {
          title: "说明",
          align: "center",
          width: 150,
          ellipsis: true,
          dataIndex: "remark"
        },
        {
          title: "操作",
          width: 120,
          align: "center",
          fixed: "right",
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
    },
    cateList() {
      let cateType = 5;//类型（1--圈子分类，2--手册分类，3--评测分类,4--省钱宝分类）
      return this.goodsCateList.filter(v => v.type === cateType).map(v => {
        return {
          value: v.cateId,
          label: v.cateName
        }
      })
    }
  },

  mounted() {
    this.search();
    this.$postJson('/category/cateList', {pageSize: 100, type: 5}).then(res => {
      this.goodsCateList = ((res.data && res.data.records) || [])
    })
  },
  methods: {
    editSuccess() {
      this.search();
    },
    modify(e, record) {
      this.$refs.modifyModal.showModal(record, 'edit');
    },
    add() {
      this.$refs.modifyModal.showModal(null, 'new');
    },
    getDelete(e, record) {
      let that = this;

      this.$confirm({
        title: "删除确认",
        content: "您确定要删除商品【" + record.goodsTitle + "】吗？！",
        centered: true,
        onOk() {
          that.$post("goods/deleteGoods", {id: record.id}).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    },

    importGoodsData() {
      let that = this;

      this.$confirm({
        title: "删除确认",
        content: "开始导入商品信息吗？导入前需要手工将临时数据导入到表m_import_goods！",
        centered: true,
        onOk() {
          that.showImporting = true
          that.$post("goods/importGoods").then((res) => {
            that.showImporting = false
            that.$message.success("成功导入【" + res.data + "】行");
            that.search();
          }).catch(() => {
            that.showImporting = false
          });
        },
      });
    },
    changeTopState(record, field = 'isTop') {
      let that = this;
      let data = {
        id: record.id,
      };
      let label = '有效状态';
      if (field == 'isTop') {
        label = '推荐状态'
      } else if (field == 'hotTop') {
        label = '热门状态'
      }
      data[field] = record[field] == 1 ? 0 : 1;
      that.showLoading = true;
      that.$postJson("goods/save", data).then(() => {
        that.showLoading = false;
        let mes = `ID：【${record.id}】设置${label}成功`
        that.$message.success(mes + "，请前往小程序查看");
        this.search();
      }).catch(() => {
        that.showLoading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
